import Modal from "../../components/modal";
import React from "react";

const AheadModal = () => (
  <Modal>
    <h3 className="leads-the-modal">Ahead.ai</h3>
    <ul className="tags">
      <li>React</li>
      <li>Redux</li>
      <li>Node.js</li>
      <li>Flow</li>
      <li>MongDB</li>
      <li>Jest</li>
      <li>Mocha</li>
    </ul>
    <p>
      Stella contacted us for our specialty in building rapid prototypes and
      minimum viable products. They had a new product they wanted to explore and
      they needed our help to bring it to life.
    </p>
    <p>
      Working to their specification we came up with a plan to build the first
      version of their new career tracking app. We delivered a bespoke system
      for users to record and track their job skills and responsibilities so
      that they could identify areas to improve. By tracking their skillsets,
      users could see their progression towards qualifying for new jobs.
    </p>
    <p>
      The biggest challenge for the project centered around the data. Only a
      small amount of sample data was available for testing, and since most of
      it was in spreadsheets that were constantly being updated, it required a
      fair amount of manipulation before it was ready to use. This data was
      going to power the system, so we needed to come up with a solution.
    </p>
    <p>
      We created an admin portal for the website so that the data could be
      viewed, manipulated, and uploaded easily. By doing this we made it much
      faster to add new data as it came in. The portal also allowed admins to
      view existing user skills and responsibilities that would form the basis
      for future recommendation algorithms.
    </p>
  </Modal>
);

export default AheadModal;
